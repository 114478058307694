/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'
import Services from 'routes/services'

// Meta Component
import Meta from 'components/Meta/Meta'

// Services Route
export default Services

// Gatsby SEO Head
export function Head({ data }) {
  return <Meta tags={data.page.seoMetaTags.tags} />
}

export const query = graphql`
  query ServicesPageQuery1 {
    page: datoCmsPage(slug: { eq: "services" }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      content
      featuredMedia {
        gatsbyImageData(
          placeholder: TRACED_SVG
          imgixParams: { fm: "jpg", bg: "#FAF7F2", auto: "compress", maxW: 600 }
        )
      }
    }
    testimonials: allDatoCmsClientTestimonial(limit: 3) {
      edges {
        node {
          id
          clientName
          clientRole
          content
          clientCompany
          isFlipped
          caseStudy {
            slug
          }
          clientLogo {
            gatsbyImageData(
              placeholder: TRACED_SVG
              imgixParams: { fm: "png", auto: "compress", h: "20", bri: -60 }
            )
          }
          clientImage {
            gatsbyImageData(
              placeholder: TRACED_SVG
              imgixParams: { fm: "jpg", auto: "compress" }
            )
          }
        }
      }
    }
  }
`
