/* eslint-disable prettier/prettier */
import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { Card, Section, Columns, Container } from 'react-bulma-components'

// Components
import ArrowLink from 'components/link-with-arrow'
import Separator from 'components/separator'

function Methods() {
  return (
    <Section>
      <Container className="content">
        <Columns>
          <Columns.Column className="is-6-desktop is-12-tablet is-mobile is-hidden">
            <Link to="/mobile-strategy-toolkit">
              <Card className="classy has-bg-green has-text-left">
                <span className="circle is-bottom-right" />
                <h2 class="title mt-0">Education</h2>
                <p className="has-text-weight-semibold is-size-3">FREE</p>
                <p className="">
                  Courses, eBooks and blog posts that help you develop apps on
                  strong foundations.
                </p>
                <Link
                  className="button is-dark is-medium "
                  to="/mobile-strategy-toolkit"
                >
                  Start learning →
                </Link>
                <StaticImage
                  height={220}
                  layout="fixed"
                  src="https://www.datocms-assets.com/20955/1693499805-education-card-cover.png?h=400"
                  className="hard-right is-hidden-mobile"
                />
              </Card>
            </Link>
          </Columns.Column>

          <Columns.Column className="is-6-desktop is-12-tablet has-text-left">
            <Link to="/services/advisory/sense-check">
              <Card className="classy has-bg-purple">
                <span className="circle is-bottom-right" />
                <h2 class="title mt-0">Sense Check</h2>
                <p className="has-text-weight-semibold is-size-4">£3,600</p>
                <p className="">
                  A quick expert review to refine your app idea and avoid costly mistakes.
                </p>
                <Link
                  className="button is-dark is-medium mb-3"
                  to="/services/advisory/sense-check"
                >
                  View Details &amp; Buy →
                </Link>
                <StaticImage
                  height={220}
                  layout="fixed"
                  src="https://www.datocms-assets.com/20955/1714160480-business-case-help-illustration.png?h=430&xmonochrome=FFBFF9"
                  className="nudge-right-2 is-hidden-mobile"
                />
              </Card>
            </Link>
          </Columns.Column>
         
          <Columns.Column className="is-6-desktop is-12-tablet is-mobile">
            <Link to="/services/advisory/app-yes-or-no">
              <Card className="classy has-bg-green has-text-left">
                <span className="circle is-bottom-right" />
                <h2 class="title mt-0">App: Yes or No?</h2>
                <p className="has-text-weight-semibold is-size-4">£15,000</p>
                <p className="">
                Figure out your app potential with in-depth market and consumer insights.
                </p>
                <Link
                  className="button is-dark is-medium mb-3"
                  to="/services/advisory/app-yes-or-no"
                >
                  View Details →
                </Link>
                <StaticImage
                  height={220}
                  layout="fixed"
                  src="https://www.datocms-assets.com/20955/1714160512-possibilities-illustration.png?h=400"
                  className="hard-right is-hidden-mobile"
                />
              </Card>
            </Link>
          </Columns.Column>
          <Columns.Column className="is-6-desktop is-12-tablet has-text-left">
            <Link to="/services/advisory/concept-design">
              <Card className="classy has-bg-blue">
                <span className="circle is-bottom-right" />
                <h2 class="title mt-0">Concept Design</h2>
                <p className="has-text-weight-semibold is-size-4">£20,000</p>
                <p className="">
                  Transform your app idea into a user-tested, actionable concept.
                </p>
                <Link
                  className="button is-dark is-medium mb-3"
                  to="/services/advisory/concept-design"
                >
                  View Details →
                </Link>
                <StaticImage
                  height={220}
                  layout="fixed"
                  src="https://www.datocms-assets.com/20955/1714160464-concept-design-illustration.png?h=200&xmonochrome=9DC1FF"
                  className="is-hidden-mobile nudge-right-1 "
                />
              </Card>
            </Link>
          </Columns.Column>
          <Columns.Column className="is-6-desktop is-12-tablet has-text-left">
            <Link to="/services/advisory/growth-diagnostic">
              <Card className="classy has-bg-orange">
                <span className="circle is-bottom-right" />
                <h2 class="title mt-0">App Growth Doctor</h2>
                <p className="has-text-weight-semibold is-size-4">£20,000</p>
                <p className="has-text-weight-semiboldx">
                  Pinpoint your app's growth challenges and unlock its revenue potential.
                </p>
                <Link
                  className="button is-dark is-medium mb-3"
                  to="/services/advisory/growth-diagnostic"
                >
                  View Details →
                </Link>
                <StaticImage
                  height={220}
                  layout="fixed"
                  src="https://www.datocms-assets.com/20955/1714160496-growth-diagnostic-illustration.png?h=200&xmonochrome=CCDAAC"
                  className="nudge-right-2 is-hidden-mobile"
                />
              </Card>
            </Link>
          </Columns.Column>
          <Columns.Column className="is-4-widescreen is-6-desktop is-12-tablet has-text-left is-hidden">
            <Link to="/services/advisory/digital-possibilities">
              <Card className="classy has-bg-orange">
                <span className="circle is-bottom-right" />
                <h2 class="title mt-0">Digital Possibilities</h2>
                <p className="has-text-weight-bold is-size-3">£15,000</p>
                <p className="">
                  A detailed look at your business strategy, customer journey
                  and internal processes.
                </p>
                <Link
                  className="button is-dark is-medium mb-5"
                  to="/services/advisory/digital-possibilities"
                >
                  View Details →
                </Link>
                <StaticImage
                  height={180}
                  layout="fixed"
                  src="https://www.datocms-assets.com/20955/1714160512-possibilities-illustration.png?h=200"
                  className="nudge-right-2 is-hidden-mobile"
                />
              </Card>
            </Link>
          </Columns.Column>
          <Columns.Column className="is-12-desktop">
            <Card className="classy has-bg-blue has-text-left">
              <span className="circle is-top-right" />
              <span className="circle is-bottom-left" />
              <Columns>
                <Columns.Column className="is-6">
                  <h2 class="title mt-0">App Development</h2>
                  <p className="has-text-weight-bold is-size-3">
                    From £180,000 per year
                  </p>
                  <p className="">
                    Hire us as your long-term app development partner focused on
                    quality and growth.
                  </p>
                  <ul>
                    <li>
                      Design experiences that resonate by employing
                      user-centered design and user research
                    </li>
                    <li>
                      Launch native apps or cross-platform Flutter apps complete
                      with back-end infrastructure
                    </li>
                    <li>
                      Achieve customer growth with ASO and data-driven
                      conversion optimisation
                    </li>
                  </ul>
                  <Link
                    className="button is-dark is-medium is-block mt-6"
                    to="/services/mobile-app-development"
                  >
                    View Details →
                  </Link>
                </Columns.Column>
                <Columns.Column className="is-5 is-offset-1 has-text-right">
                  <StaticImage
                    src="https://www.datocms-assets.com/20955/1693499877-app-development-card-cover.png"
                    className="nudge-left mt-5"
                  />
                </Columns.Column>
              </Columns>
            </Card>
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  )
}

export default Methods
