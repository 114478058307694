import PropTypes from 'prop-types'
import React from 'react'

// Components
import Layout from 'components/layout'
import Testimonials from 'components/testimonials'
import Clients from 'components/clients'
import Customers from 'components/customers'
import Objectives from 'components/objectives'
import LeeQuote from 'components/leeQuote'

// Page COmponents
import ServicesHero from './components/services-hero'
import Stats from './components/stats'
import Methods from './components/method'
import ProblemSolving from './components/problem-solving'
import DeliverApps from './components/deliver-apps'
import TrustUs from './components/trust-us'

function Services({ data: { page, testimonials } }) {
  return (
    <Layout>
      <ServicesHero page={page} />
      <Stats />
      <Methods />
      <Testimonials testimonials={testimonials} />
      <ProblemSolving />
      <DeliverApps />
      <Clients />
      <TrustUs />
      <Objectives />
      <LeeQuote />
    </Layout>
  )
}

Services.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Services
